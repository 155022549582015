<template>
  <div style="padding: 20px;">
    <portal to="view-title">
      About
    </portal>
    <b-tabs>
      <b-tab-item label="System information">
        <h2>General</h2>
        <span><b>API url : </b>{{url}}</span>
        <h2>Git info</h2>
        <b-message>
          <b>branch:</b> {{ gitinfo.git.branch }}<br/>
          <b>commit time:</b> {{new Date(gitinfo.git.commit.time).toJSON()}}<br/>
          <b>commit message:</b> {{gitinfo.git.commit.message.full}}<br/>
        </b-message>
        <h2>API info</h2>
        <pre>{{JSON.stringify(systemInfo, null, 2)}}</pre>
        <h2>App configuration</h2>
        <ul>
          <li>Has pages: {{has_pages}}</li>
          <li>env: {{env}}</li>
        </ul>
      </b-tab-item>
      <b-tab-item label="Debug">
        <h2>Websocket</h2>
        <a class="button" @click="testWebsocket">
          Test
        </a>
        <b-message v-if="socketResponse">
          {{socketResponse}}
        </b-message>
      </b-tab-item>
   </b-tabs>
  </div>
</template>
<script>
import gitinfo from '@/../git.properties.json';
import Api from '@/core/Api';
import socket from '@/core/socket';

export default {
  data() {
    Api.get('/system_info').then((res) => {
      this.systemInfo = res.data.result;
    });
    const socketCallback = (response) => {
      this.socketResponse = `[${new Date().toLocaleString()}] Websocket respose ok: ${response}`;
    };
    socket.io.on('pong', socketCallback);
    return {
      env: process.env,
      has_pages: process.env.VUE_APP_HAS_PAGES,
      url: process.env.VUE_APP_BASE_URL,
      gitinfo,
      systemInfo: 'loading',
      socketCallback,
      socketResponse: undefined,
    };
  },
  beforeDestroy() {
    if (this.socketCallback) {
      socket.io.off('pong', this.socketCallback);
    }
  },
  methods: {
    testWebsocket() {
      socket.io.emit('ping', 'test');
    },
  },
};
</script>
